<template>
    <div class="trx-body">
        <div class="header-title">{{ $t("Request History") }}</div>
        <div class="d-flex mt-3 ml-2 flex-wrap gap-10">
            <div class="table-header">
                <div class="table-header-text">{{ $t("DEPOSIT ID") }}</div>
                <div>
                    <input type="text" class="txID-input" :placeholder="$t(`Enter TX/ID`)" />
                </div>
            </div>
            <div class="table-header">
                <div class="table-header-text">{{ $t("COIN") }}</div>
                <div>
                    <select v-model="coinSelected" @change="changeSelectedCoin" id="coinSortList" class="tx-dropdown">
                        <option v-for="coinOption in coinOptions" :value="coinOption" :key="coinOption.id">
                            {{ coinOption.api_symbol }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="table-header">
                <div class="table-header-text">{{ $t("STATUS") }}</div>
                <div>
                    <select v-model="statusSelected" id="coinList" class="tx-dropdown">
                        <option v-for="status in allStatus" :value="status.value" :key="`status${status.value}`">
                            {{ status.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="table-header refresh-div ml-2">
                <b-button class="refresh-btn" @click="updateTransaction">
                    <span class="mr-2">{{ $t("Refresh") }}</span>
                    <b-icon icon="arrow-repeat" :animation="refreshAnimation"></b-icon>
                </b-button>
            </div>
        </div>

        <div class="d-flex justify-content-center align-items-center h-100 mt-4 spinning" v-if="isLoading">
            <b-spinner variant="success" label="Spinning"></b-spinner>
        </div>

        <div class="trx-table" v-if="switch_tab === 1 && !isLoading">
            <b-table tbody-tr-class="rowClass" :items="request_deposit_histories" :per-page="perPage"
                :current-page="currentPage" :fields="requestHistoryFields" :total-rows="totalRows" show-empty small
                responsive detailed empty-filtered-text="No Data" class="trx-table" id="request-history-table"
                @row-clicked="handleRowClicked">
                <!-- start table header -->
                <template v-slot:head(deposit_id)>
                    <div class="table-header-content">
                        <div>{{ $t("DEPOSIT ID") }}</div>
                    </div>
                </template>

                <template v-slot:head(token)>
                    <div class="table-header-content">
                        <div>{{ $t("COIN") }}</div>
                    </div>
                </template>

                <template v-slot:head(amount)>
                    <div class="table-header-content">
                        <div>{{ $t("AMOUNT") }}</div>
                    </div>
                </template>

                <template v-slot:head(start_time)>
                    <div class="table-header-content">
                        <div>{{ $t("START TIME") }}</div>
                    </div>
                </template>

                <template v-slot:head(end_time)>
                    <div class="table-header-content">
                        <div>{{ $t("END TIME") }}</div>
                    </div>
                </template>

                <template v-slot:head(status)>
                    <div class="table-header-content">
                        <div>{{ $t("STATUS") }}</div>
                    </div>
                </template>

                <template v-slot:head(details)>
                    <div class="table-header-content">
                        <div>{{ $t("DETAILS") }}</div>
                    </div>
                </template>
                <!-- end table header -->

                <!-- start table body -->
                <template #cell(hash)="row">
                    <div class="trxID-td" v-if="row.item.hash == null || row.item.hash == ''">—</div>
                    <div class="trxID-td" v-else>
                        {{
                            row.item.deposit_id == null || row.item.deposit_id == '' ? '—' :
                                txIDFormatter(row.item.deposit_id)
                        }}
                    </div>
                </template>
                <template #cell(token)="row">
                    {{ row.item.token.api_symbol }}
                </template>
                <template #cell(amount)="row">
                    {{ addCommas(row.item.amount) }}
                </template>
                <template #cell(start_time)="row">
                    {{ timestampFormatter(row.item.start) }}
                </template>
                <template #cell(end_time)="row">
                    {{ timestampFormatter(row.item.end) }}
                </template>
                <template #cell(status)="row">
                    <span class="table_status" :style="
                        (row.item.status == 'success') ? 'color: var(--primary)' :
                            (row.item.status == 'fail') ? 'color: var(--danger)' :
                                'color: var(--info)'
                    ">
                        {{ row.item.status }}
                    </span>
                </template>
                <template #cell(details)="row">
                    <b-icon-box-arrow-up-right type="button"
                        @click="goToRequestDepositDetails(row.item.deposit_id)"></b-icon-box-arrow-up-right>
                </template>
                <template #empty="scope">
                    <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
                </template>
                <!-- end table body -->

                <!-- start expanded body -->
                <template slot="row-details" slot-scope="row">
                    <div class="d-flex flex-column w-100">
                        <div class="d-flex flex-row w-100">
                            <div class="expand-left">{{ $t("TX ID") }}</div>
                            <div class="expand-right">
                                <div class="mr-3 trxID-td">{{
                                    row.item.hash == null || row.item.hash == '' ? "—" :
                                        row.item.hash
                                }}</div>
                                <div class="tooltip__layout" v-if="!(row.item.hash == null || row.item.hash == '')">
                                    <b-icon-box-arrow-up-right class="mx-2" type="button"
                                        @click.stop.prevent="toExplorer(coinSelected.network.symbol.toLowerCase(), row.item.hash)"></b-icon-box-arrow-up-right>
                                    <button class="icon-btn pl-2"
                                        @click="copyToClip(row.item.hash, `hash` + row.item.id)">
                                        <inline-svg :src="require('../assets/icon/copy.svg')" width="18"
                                            aria-label="copy"></inline-svg>
                                        <span class="tooltip__content" :id="'hash' + row.item.id">{{
                                            $t("Copied!")
                                        }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-row w-100">
                            <div class="expand-left">{{ $t("To Address") }}</div>
                            <div class="expand-right">
                                <div class="mr-3">{{ row.item.to == null || row.item.to == '' ? "—" : row.item.to }}
                                </div>
                                <div class="tooltip__layout" v-if="!(row.item.to == null || row.item.to == '')">
                                    <button class="icon-btn pl-2"
                                        @click="copyToClip(row.item.to, `to_address` + row.item.id)">
                                        <inline-svg :src="require('../assets/icon/copy.svg')" width="18"
                                            aria-label="copy"></inline-svg>
                                        <span class="tooltip__content" :id="'to_address' + row.item.id">{{
                                            $t("Copied!")
                                        }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- end expanded body -->
            </b-table>

            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="10"
                aria-controls="request-history-table" align="center"></b-pagination>
        </div>
    </div>
</template>
<script>
import api from '../helper/api.js';
import explorer from '../helper/explorer.js';
import moment from "moment";
import InlineSvg from "vue-inline-svg";

export default {
    name: "RequestHistory",
    components: {
        InlineSvg,
        // VueQr
    },
    data() {
        return {
            switch_tab: 1,
            request_deposit_histories: null,
            requestHistoryFields: [
                { key: "deposit_id", label: "DEPOSIT ID" },
                // { key: "receiving_address", label: "REECEIVING ADDRESS" },
                { key: "token", label: "COIN" },
                { key: "amount", label: "AMOUNT" },
                { key: "start_time", label: "START TIME" },
                { key: "end_time", label: "END TIME" },
                { key: "status", label: "STATUS" },
                { key: "details", label: "DETAILS" }
            ],
            coin_sort: [
                { sortID: 0, sortName: 'All' },
                { sortID: 1, sortName: 'Alphabet A-Z' },
                { sortID: 2, sortName: 'Alphabet Z-A' },
            ],
            dateTime_sort: [
                { sortID: 0, sortName: 'All' },
                { sortID: 1, sortName: 'Past 7 Days' },
                { sortID: 2, sortName: 'Past 30 Days' },
                { sortID: 3, sortName: 'Past 90 Days' },
            ],
            status_sort: [
                { sortID: 0, sortName: 'All' },
                { sortID: 1, sortName: 'Pending' },
                { sortID: 2, sortName: 'Failed' },
            ],
            coinSort_selected: 'All',
            dateTime_sort_selected: 'All',
            status_sort_selected: 'All',
            currentPage: 1,
            totalRows: 0,
            perPage: 10,
            isLoading: false,
            opened: [],
            allOpenRows: [],
            coinSelected: [],
            coinOptions: [],
            allStatus: [
                { value: null, text: this.$t("All") },
                { value: "pending", text: this.$t("Pending") },
                { value: "deposited", text: this.$t("Deposited") },
                { value: "unused", text: this.$t("Unused") },
                { value: "unmatched", text: this.$t("Unmatched") }
            ],
            statusSelected: null,
            refreshStatus: "Update",
            refreshAnimation: "",
        }
    },

    watch: {
        currentPage: {
            async handler() {
                this.isLoading = true
                await this.fetchRequestDeposits();
            },
            deep: true,
        },

        perWithdraw: {
            async handler() {
                this.isLoading = true
                await this.fetchRequestDeposits();
            },
            deep: true,
        },

        statusSelected() {
            this.isLoading = true;
            this.fetchRequestDeposits();
        }
    },

    async mounted() {
        this.isLoading = true;
        await this.fetchCoins();
        await this.fetchRequestDeposits();
        // this.isLoading = false;
    },

    methods: {

        async copyToClip(address, id) {
            await this.$copyText(address).then(function () {
                var x = document.getElementById(id);
                x.classList.add("show");
                setTimeout(function () {
                    x.classList.remove("show");
                }, 1000);
            })
        },

        async fetchCoins() {
            try {
                const response = await api.apiCall('core', 'GET', '/tokens/get-all');
                this.coinOptions = response.message;
                // this.coinSelected = this.coinOptions.filter((item)=>item.api_symbol == 'TRX')[0];
                this.coinSelected = this.coinOptions[0];


            } catch (error) {
                console.error(error);
            }
        },

        async fetchRequestDeposits() {
            try {
                const response = await api.apiCall(this.coinSelected.network.symbol.toLowerCase(), 'GET', `/deposit-request/get-all`, {
                    order: 'DESC',
                    page: this.currentPage,
                    take: 10,
                    status: this.statusSelected,
                    api_symbol: this.coinSelected.api_symbol,
                });
                this.request_deposit_histories = response.message.data;
                this.totalRows = response.message.meta.itemCount;
                this.rows = response.message.meta.itemCount;
                this.currentPage = response.message.meta.page
                this.perPage = this.request_deposit_histories - (10 * this.currentPage)
                this.isLoading = false;
            } catch (error) {
                console.error(error);
            }
        },

        async updateTransaction() {
            this.refreshStatus = 'Updating...';
            this.refreshAnimation = "spin";


            await this.fetchRequestDeposits();


            setTimeout(() => {
                this.refreshAnimation = "";
            }, 1000);
            this.refreshStatus = 'Update'
        },

        goToRequestDepositDetails(id) {
            console.log(id);
            this.$router.push({ name: 'DepositDetail', params: { network: this.coinSelected.network.symbol.toLowerCase(), platformId: localStorage.getItem('platform_id'), id: id } });
        },

        addCommas(nStr) {
            nStr += ''
            var x = nStr.split('.')
            var x1 = x[0]
            var x2 = x.length > 1 ? '.' + x[1] : ''
            var rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2')
            }
            return x1 + x2
        },

        timestampFormatter(value) {
            if (value) {
                // value = new Date(value/1000);
                return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
            }
        },

        handleRowClicked(item) {
            this.allOpenRows.map((ele) => {
                if (ele.id !== item.id && ele._showDetails) {
                    this.$set(ele, "_showDetails", !ele._showDetails);
                }
            });
            this.allOpenRows = [];
            this.$set(item, "_showDetails", !item._showDetails);
            this.allOpenRows.push(item);
        },

        changeSelectedCoin() {
            this.fetchRequestDeposits();
            this.isLoading = true;
        },

        toExplorer(network, hash) {
            let url = explorer.explorerLink(network, 'tx', hash)
            window.open(url, '_blank').focus();
        }
    },
}
</script>

<style>
@import '../css/styles.css';

.trx-body {
    height: 100%;
    overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.trx-body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.trx-body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.rowClass:hover {
    cursor: pointer;
}

.b-table-details:hover {
    cursor: auto;
}

.balance-body {
    margin: 0% 17%;
    height: 100%;
    overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.balance-body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.balance-body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.top-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin: 0% 10%; */
    margin-top: 4rem;
}

.table-responsive {
    display: inline-table;
}

.trx-table {
    font-size: 13px;
    text-align: center;
    margin-top: 1rem;
    /* padding-left: 0.5rem */
}

.trx-table th,
.trx-table td {
    font-size: 16px;
    border: 1px !important;
}

.trx-table table {
    border-collapse: separate;
    border-spacing: 0 8px;
}

.trx-table td {
    border-bottom: none;
    padding: 1px 20px;
    vertical-align: middle;
}

.trx-table td:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.trx-table td:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.rowClass {
    background-color: var(--light);
}

.trx-table.b-table-has-details {
    background-color: aliceblue;
}

.rowClass:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.trx-table.b-table-details:hover {
    cursor: auto;
    background-color: white;
}

.expand-left {
    width: 15%;
    padding: 7px 0px;
    text-align: left;
}

.expand-right {
    width: 85%;
    text-align: left;
    padding-left: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}


select {
    text-align-last: center;
}

.bal-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.total-balance {
    color: var(--info);
    font-size: 38px;
}

.balance-token-text1 {
    font-size: 18px;
}

.balance-token-text2 {
    font-size: 13px;
}

.bal-btn {
    background-color: transparent;
    /* min-width: 100px; */
    width: 30%;
    border-radius: 30px;
    border: 2px solid var(--primary);
    color: var(--primary);
    font-weight: bold;
    cursor: pointer;
    /* margin-left: 2rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
}

.bal-btn:hover,
.bal-btn:active,
.bal-btn:focus {
    color: var(--primary) !important;
    fill: var(--primary) !important;
    border: 2px solid var(--primary) !important;
    background-color: var(--primary-10) !important;
    /* box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px; */
}

.bal-btn #swap {
    fill: var(--primary);
    padding-left: 0.8rem;
}

.bal-btn div {
    padding-left: 1rem;
}

.bal-btn img {
    width: 30px;
}

/* Child Address Table */
.add-child-btn {
    background-color: var(--primary);
    /* border: none; */
    border-radius: 20px;
    padding: 0px 20px 0 40px;
    /* width: 100px; */
    color: var(--light);
    border: 1px solid var(--primary);
    height: 25px;
    /* padding-left: 45px; */
    position: relative;
    min-width: fit-content;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.add-child-btn:before {
    content: "";
    display: block;
    background-color: var(--primary);
    border-radius: 100%;
    width: 35px;
    height: 35px;
    left: -1px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 31%);
    bottom: -6px;
    position: absolute;
}

#plus-icon {
    position: absolute;
    left: 6px;
    top: -1px;
    color: white;
}

.add-child-btn:hover,
.add-child-btn:focus,
.add-child-btn:hover text {
    color: var(--primary);
    background-color: var(--light);
    fill: var(--light);
    border: 1px solid var(--primary);
}

/* .search-input {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
}

.search-input input {
    border-radius: 20px;
    padding: 0px 15px;
    height: 25px;
}

.search-input:after {
    content: "";
    display: block;
    background-color: white;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 31%);
    bottom: -6px;
    right: 0;
    position: absolute;
} */

.search-icon {
    position: absolute;
    z-index: 2;
    right: 7px;
}

#child-add-table tbody::before {
    content: '';
    display: block;
    height: 10px;
}

#child-add-table th {
    font-size: 14px;
    text-align: center;
}

#child-add-table tr {
    background-color: var(--light);
    color: black;
    text-align: center;
}

#child-add-table td {
    vertical-align: middle;
}

#child-add-table tr:first-child td:first-child {
    border-radius: 30px 0px 0px 30px;
}

#child-add-table tr:first-child td:last-child {
    border-radius: 0px 30px 30px 0px;
}

.child-table-th {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 10px;
    /* padding-bottom: 10px; */
}

.table-header-text {
    text-align: initial;
    margin-left: 15px;
    font-size: 16px;
    font-weight: bold;
}

.table-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.txID-input {
    background-color: rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 15px;
    min-width: 100px;
    width: 150px;
    color: black;
    text-indent: 10px;
    font-size: 18px;
    height: 35px;
}

.txID-readonly {
    background-color: rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 15px;
    min-width: 100px;
    width: 120px;
    color: black;
}

.tx-dropdown {
    border: none;
    color: black;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    height: 35px;
}

.refresh-div {
    align-items: baseline;
    margin: 0;
    display: flex;
    justify-content: flex-end;
}

/* #add-child-form input {
    background-color: var(--info-10);
    border: none;
    color: var(--info);
    text-align: center;
    border-radius: 30px;
    width: 100%;
} */

.icon-btn {
    padding: 0px;
}

.balance-token {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 35%;
}

.balance-withdrawal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.deposit-balance {
    display: flex;
    flex-direction: row;
    width: 65%;
    justify-content: space-between;
}

.deposit-balance>div {
    width: 45%;
}

.balance-title {
    font-size: 1rem;
    /* font-weight: bold; */
    color: var(--primary);
    text-align: center;
}

.balance-content {
    font-size: 2rem;
    text-align: center;
    color: var(--info);
}

/* Send Modal */
fieldset {
    color: #555;
    font-family: sans-serif;
    border: none;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

fieldset::after {
    content: "___  ___  ___  ___  ___  ___";
    display: block;
    position: absolute;
    top: 35px;
    white-space: pre;
    color: white;
}

input#tradingPin {
    position: relative;
    font-size: 16px;
    z-index: 2;
    border: none;
    background: transparent;
    width: 300px;
    text-indent: 27px;
    letter-spacing: 25.6px;
    font-family: Courier;
    margin-left: 55px;
    text-align: left;
}

input#tradingPin:focus {
    outline: none;
}

/* Receive Modal */
#receive-content {
    background: transparent;
    border: none;
}

#receive-content .card-header {
    border-bottom: 1px solid white;
    border-top: 2px solid white;
    background: transparent;
    padding: 0rem 0rem 0.75rem 0rem;
    overflow: hidden;
    overflow-x: scroll;
}

#receive-content .nav {
    flex-wrap: nowrap;
}

#receive-content .nav-item {
    padding-top: 2px;
    margin-left: 5px;
}

#receive-content .nav-item a {
    width: 150px;
    text-align: center;
    border: none;
    color: #bbbbbb;
}

#receive-content .nav-item a:hover {
    border-bottom: 2px solid var(--primary);
    color: var(--primary);
}

#receive-content .nav-item .active {
    border-bottom: 2px solid var(--primary);
    color: var(--primary);
    background: transparent;
}

.modal-wallet-address {
    color: #707070;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
}

.withdraw {
    color: var(--danger);
}

.table-responsive {
    display: inline-table;
}

@media only screen and (max-width:768px) {
    .balance-token {
        width: 100%;
        justify-content: center;
        margin-bottom: 1.5rem;
    }

    .balance-withdrawal {
        flex-direction: column;
    }

    .deposit-balance {
        width: 100%;
    }

    .table-responsive {
        display: block;
    }

    .balance-body {
        margin: 5%;
    }

    .total-balance {
        font-size: 30px;
    }

    /* .search-input {
        margin-left: 1rem;
    } */

    .bal-button {
        flex-direction: column;
    }

    .bal-btn {
        width: 100%;
        margin-bottom: 1rem;
    }
}
</style>