export default {
    explorerLink(network, type, address){
        let baseUrl;
        switch (network) {
            case 'trx':
                var trxSearch = {'tx': 'transaction', 'address': 'address'};
                baseUrl = process.env.VUE_APP_TRX_EXPLORER + trxSearch[type];
                break;

            case 'bsc':
                var bscSearch = {'tx': 'tx', 'address': 'address'};
                baseUrl = process.env.VUE_APP_BSC_EXPLORER + bscSearch[type];
                break;

            case 'eth':
                var ethSearch = {'tx': 'tx', 'address': 'address'};
                baseUrl = process.env.VUE_APP_ETH_EXPLORER + ethSearch[type];
                break;

            default:
                break;
        }

        let url = baseUrl + '/' + address
        return url

    }

}